import React from 'react';
import { BoxProps } from '@mui/material';
import { BreadcrumbTitle } from '@front/ui';

import useRouteBreadcrumbsValues from '../../breadcrumbs/useRouteBreadcrumbsValues';

export default function PageTitle({
  customTitle,
  sx,
}: {
  customTitle?: string;
  sx?: BoxProps['sx'];
}) {
  const breadcrumbsValues = useRouteBreadcrumbsValues();
  return (
    <BreadcrumbTitle
      customTitle={customTitle}
      sx={sx}
      breadcrumbsValues={breadcrumbsValues}
    />
  );
}
